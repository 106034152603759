import { useState, useEffect } from "react";

import './Details.css';

import Comp_Topic from "components/Topic";
import Comp_Input from "components/Input";
import Comp_Select from "components/Select";
import Comp_Textarea from "components/Textarea";
import Comp_InputFile from "components/InputFile";

import { GetListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Reg_Classe } from "services/Register";

import { colorIcon, defaultColor } from "fixedData";
import { Svg_Delete } from "services/SvgFile";

export default function Page_Classes(props){

    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));
    const [ idPageModule, setIdPageModule ] = useState(GetListPag('currentPageIdModule'));
    const [ idPageClasse, setIdPageClasse ] = useState(GetListPag('currentPageIdClasse'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('courses'));

    const [ title, setTitle ] = useState(InitialData('title'));
    const [ releaseCourse, setReleaseCourse ] = useState(InitialData('release_course'));
    const [ releaseCourseForDay, setReleaseCourseForDay ] = useState(InitialData('release_course_days_or_date'));
    const [ duration, setDuration ] = useState(InitialData('duration'));
    const [ durationQtdDay, setDurationQtdDay ] = useState(InitialData('duration_qtd_day'));
    const [ idModule, setIdModule ] = useState(InitialData('id_module'));
    const [ newModule, setNewModule ] = useState('');
    const [ typeVideo, setTypeVideo ] = useState(InitialData('type_video'));
    const [ video, setVideo ] = useState(InitialData('video'));
    const [ text, setText ] = useState(InitialData('text'));
    const [ download, setDownload ] = useState(InitialData('download'));

    function InitialData(type){
        const newData = GetDataPage('courses').find(item => item.id == idPage);   
        if(newData){
            const newDataModule = newData['classes'].find(item => item.id == idPageModule);  
            if(newDataModule){
                const newDataClasse = newDataModule['list'].find(item => item.id == idPageClasse);  
                if(newDataClasse){
                    return newDataClasse[type]; 
                }
                if(type === 'release_course'){
                    return { "value": 1, "label": "Imediato" };
                }
                if(type === 'duration'){
                    return { "value": "Não", "label": "Não" };
                }
                if(type === 'id_module'){
                    return { "value": "", "label": "Selecione uma opção" };
                }       
                if(type === 'type_video'){
                    return { "value": "Vimeo", "label": "Vimeo" };
                }
                if(type === 'download'){
                    return [];
                }
            }
            if(type === 'release_course'){
                return { "value": 1, "label": "Imediato" };
            }
            if(type === 'duration'){
                return { "value": "Não", "label": "Não" };
            }
            if(type === 'id_module'){
                return { "value": "", "label": "Selecione uma opção" };
            }       
            if(type === 'type_video'){
                return { "value": "Vimeo", "label": "Vimeo" };
            }
            if(type === 'download'){
                return [];
            }
        }
        if(type === 'release_course'){
            return { "value": 1, "label": "Imediato" };
        }
        if(type === 'duration'){
            return { "value": "Não", "label": "Não" };
        }
        if(type === 'id_module'){
            return { "value": "", "label": "Selecione uma opção" };
        }
        if(type === 'type_video'){
            return { "value": "Vimeo", "label": "Vimeo" };
        }
        if(type === 'download'){
            return [];
        }
        return '';
    }

    function ShowModule(){
        const newData = [{ "value": "newData", "label": "Adicionar novo modulo" }];
        GetDataPage('category').map((elem, index)=>{
            if(elem.type === 'module'){
                newData.push({ "value": elem.id, "label": elem.name });
            }
        })
        return newData;
    }

    /* download */
    function HandleData(type, index, value){
        const newData = [...download];
        newData[index][type] = value;
        setDownload(newData);
    }
    function DeleteData(id, index, value){
        if(id == 0){
            const newData = [...download];
            newData.splice(index, 1);
            setDownload(newData);
        }else {
            props.AltAccess("courses", "delete_download", "Deletar download", id, value, 0);
        }   
    }
    /* end */

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Classe(props.idUser, idPage, idPageClasse, title, releaseCourse['value'], 7, duration['value'], durationQtdDay, idModule['value'], newModule, typeVideo['value'], video, text, download, props.CallbackSuccess, props.CallbackError);
    }

    useEffect(()=>{
        RegisterDataPage('courses', setDataPage);

        return ()=>{
            UnRegisterDataPage('courses', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'));
        setIdPageModule(GetListPag('currentPageIdModule'));
        setIdPageClasse(GetListPag('currentPageIdClasse'));

        setTitle(InitialData('title'));
        setReleaseCourse(InitialData('release_course'));
        setReleaseCourseForDay(InitialData('release_course_days_or_date'));
        setDuration(InitialData('duration'));
        setDurationQtdDay(InitialData('duration_qtd_day'));
        setIdModule(InitialData('id_module'));
        setNewModule('');
        setTypeVideo(InitialData('type_video'));
        setVideo(InitialData('video'));
        setText(InitialData('text'));
        setDownload(InitialData('download'));
    }, [dataPage, idPage]);

    useEffect(()=>{
        setReleaseCourseForDay("");
    }, [releaseCourse]);

    return(
        <form className="page_content page_classes" onSubmit={ SaveData }>
            <Comp_Topic type={ true } icon="save" SearchInput="" search="" title="Informações do aula" OpenPage={ props.OpenPage } page="courses_details" idPage={ idPage } typePage="second" />
            
            <div className="show_page_data">
                <div className="type_title">Dados da aula</div>
                <div className="list_input_data">
                    <Comp_Input className="" type="text" index="not" setValue={ setTitle } value={ title } input="" maxLength={ 140 } placeholder="" required={ true } name="Título*" />

                    <Comp_Select index="not" name="Tipo de liberação*" className="type_release" opt={ [{ "value": 1, "label": "Imediato" }, { "value": 2, "label": "7 Dias após compra" }] } setValue={ setReleaseCourse } value={ releaseCourse } input="" />

                    {/* 
                    {
                        releaseCourse['value'] === 2 ?
                            <Comp_Input className="qtd_day" type="text" index="not" setValue={ setReleaseCourseForDay } value={ releaseCourseForDay } input="" maxLength={ 4 } placeholder=" " required={ true } name="Qtd de dia*" />
                            : releaseCourse['value'] === 3 ?
                                <Comp_Input className="input_date" type="date" index="not" setValue={ setReleaseCourseForDay } value={ releaseCourseForDay } input="" placeholder=" " required={ true } name="Qtd de dia*" />
                        : null
                    } 
                    */}

                    <Comp_Select index="not" name="Duração*" className="type_video" opt={ [{ "value": "Sim", "label": "Sim" }, { "value": "Não", "label": "Não" }] } setValue={ setDuration } value={ duration } input="" />

                    {
                        duration['value'] === 'Sim' ?
                            <Comp_Input className="qtd_day" type="text" index="not" setValue={ setDurationQtdDay } value={ durationQtdDay } input="" maxLength={ 4 } placeholder=" " required={ true } name="Qtd de dias*" />
                        : null
                    }
                    
                    <Comp_Select index="not" name="Modulo*" className="type_category" opt={ ShowModule() } setValue={ setIdModule } value={ idModule } input="" />

                    {
                        idModule['value'] === 'newData' ?
                            <Comp_Input className="" type="text" index="not" setValue={ setNewModule } value={ newModule } input="" maxLength={ 140 } placeholder="" required={ true } name="Nome do novo modulo*" />
                        : null
                    }
                </div>

                <div className="list_input_data">
                    <Comp_Select index="not" name="Tipo de vídeo*" className="type_video" opt={ [{ "value": "Vimeo", "label": "Vimeo" }, { "value": "Youtube", "label": "Youtube" }] } setValue={ setTypeVideo } value={ typeVideo } input="" />

                    <Comp_Input className="" type="text" index="not" setValue={ setVideo } value={ video } input="" maxLength={ 140 } placeholder=" " required={ true } name="Link do vídeo*" showVideo={ typeVideo['value'] } OpenFile={ props.OpenFile } />  
                    
                    <Comp_Textarea index="not" name="Descrição" className="" setValue={ setText } value={ text } />
                </div>
            </div>

            <div className="show_page_data">
                <div className="type_title">
                    <div className="">Download</div>
                    <div className="" onClick={ ()=>{ setDownload([...download, { "id": 0, "title": "", "file": "", "status": false }]); } }>Adicionar nova opção</div>
                </div>
                <div className="list_input_data">                    
                    {
                        download.length > 0 ?
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <thead>
                                <tr>
                                    <th width="20" align="center" style={ { backgroundColor: defaultColor } }>#</th>
                                    <th style={ { backgroundColor: defaultColor } }>Título</th>
                                    <th width="80" style={ { backgroundColor: defaultColor } }>Arquivo</th>
                                    <th width="20" align="right" style={ { backgroundColor: defaultColor } }>#</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    download.map((elem, index)=>{
                                        return(
                                            <tr key={ index }>
                                                <td align="center">{ index + 1 }</td>
                                                <td>
                                                    <Comp_Input className="" type="text" index={ index } setValue={ HandleData } value={ elem.title } input="title" maxLength={ 140 } placeholder=" " required={ true } name="" />  
                                                </td>
                                                <td align="left">
                                                    <Comp_InputFile index={ index } setValue={ HandleData } value={ elem.file } setStatus={ HandleData } status={ elem.status } OpenFile={ props.OpenFile } thumbnail={ elem.file } input="file" title1="Adicionar arquivo" title2="Arquivo adicionado" />
                                                </td>
                                                <td align="right">
                                                    <div className="" onClick={ ()=>{ DeleteData(elem.id, index, elem.title) } }>
                                                        <Svg_Delete className="icons" color="#f00000" />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        :
                        <div className="no_data">Nenhum dado encontrado...</div>
                    }
                </div>
            </div>
        </form>
    )
}