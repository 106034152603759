import { useEffect, useState } from 'react';

import './Input.css';

import { Svg_Vimeo, Svg_Youtube } from 'services/SvgFile';
import { colorIcon } from 'fixedData';

export default function Comp_Input(props){

    const [ type, setType ] = useState(props.showVideo);
    const [ showData, setShowData ] = useState(props.value);

    useEffect(()=>{
        setType(props.showVideo);
        setShowData(props.value);
    }, [props.showVideo, props.value]);    

    return(
        <div className={ props.className == '' ? "Comp_Input space_div" : props.className === "all" ? "Comp_Input input_all" : "Comp_Input" }>
            <input type={ props.type } className={ props.className } onChange={ (e)=>{ props.index === "not" ? 
                props.showPriceBr ? props.showPriceBr(props.setValue, e.target.value) : props.setValue(e.target.value) : props.setValue(props.input, props.index, e.target.value) } } value={ props.value } maxLength={ props.maxLength } placeholder={ props.placeholder ? props.placeholder : "digite aqui a informação..." } required={ props.required ? true : false } readOnly={ props.readOnly ? props.readOnly : false } />

            <div className="name">{ props.name }</div>
            {
                showData !='' ?
                    props.showVideo === "Vimeo" ?
                        <div className="" onClick={ ()=>{ props.OpenFile(props.showVideo, showData) } }>
                            <Svg_Vimeo className="icons" color={ colorIcon } />
                        </div> :                       
                            props.showVideo === "Youtube" ?
                            <div className="" onClick={ ()=>{ props.OpenFile(props.showVideo, showData) } }>
                                <Svg_Youtube className="icons" color={ colorIcon } />
                            </div>
                    : null
                : null
            }
        </div>
    )
}