import { useState, useEffect } from "react";

import './Details.css';

import Comp_Topic from "components/Topic";
import Comp_Input from "components/Input";
import Comp_Select from "components/Select";
import Comp_Textarea from "components/Textarea";
import Comp_InputFile from "components/InputFile";

import { GetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Reg_Site } from "services/Register";
import { Svg_Delete } from "services/SvgFile";

export default function Page_Site_Details(props){

    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('site'));

    const [ origin, setOrigin ] = useState(InitialData('origin'));
    const [ title, setTitle ] = useState(InitialData('title'));
    const [ subtitle, setSubtitle ] = useState(InitialData('subtitle'));
    const [ text, setText ] = useState(InitialData('text'));
    const [ type, setType ] = useState(InitialData('type'));
    const [ file, setFile ] = useState(InitialData('file'));
    const [ fileStatus, setFileStatus ] = useState(false);
    const [ bgFile, setBgFile ] = useState(InitialData('bg_file'));
    const [ bgFileStatus, setBgFileStatus ] = useState(false);
    const [ imgSlideShow, setImgSlideShow ] = useState(InitialData('img_slide_show'));
    const [ imgSlideShowStatus, setImgSlideShowStatus ] = useState(false);
    
    const [ btn, setBtn ] = useState(InitialData('btn'));
    const [ slideshow, setSlideshow ] = useState(InitialData('slideshow'));

    function InitialData(type){
        const newData = GetDataPage('site').find(item => item.id == idPage);   
        if(newData){
            return newData[type];            
        }
        if(type === 'type'){
            return { "value": 0, "label": "Lista de imagem" };
        }
        if(type === 'btn' || type === 'slideshow'){
            return [];
        }
        return '';
    }

    /* crop */
    function PopUpCrop(type, file, id){
        SetModalData('PhotoCrop', { "idPage": idPage, "type": type, "file": file, "id": id, "showImg": URL.createObjectURL(file) });
        SetModalState('PhotoCrop', true);
    }
    /* end */

    /* btn */
    function HandleDataBtn(type, index, value){
        const newData = [...btn];
        if(type === "file"){
            if(value){
                newData[index]['file'] = value;
                newData[index]['status'] = true;
            }else {
                newData[index]['file'] = '';
                newData[index]['status'] = false;
            }
        }else {
            newData[index][type] = value;
        }
        setBtn(newData);
    }
    /* end */

    /* slideshow */
    function HandleDataSlideshow(type, index, value){
        const newData = [...slideshow];
        if(type === "file"){
            if(value){
                newData[index]['file'] = value;
                newData[index]['status'] = true;
            }else {
                newData[index]['file'] = '';
                newData[index]['status'] = false;
            }
        }else {
            newData[index][type] = value;
        }
        setSlideshow(newData);
    }
    function DeleteDataSlideshow(id, index, value){
        if(id == 0){
            const newData = [...slideshow];
            newData.splice(index, 1);
            setSlideshow(newData);
        }else {
            props.AltAccess("site", "delete_slideshow", "Deletar slideshow", id, value, 0)
        }
    }
    /* end */

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Site(props.idUser, idPage, imgSlideShow, title, subtitle, text, type, file, bgFile, btn, slideshow, props.CallbackSuccess, props.CallbackError);
    }

    useEffect(()=>{
        RegisterDataPage('site', setDataPage);
        return ()=>{
            UnRegisterDataPage('site', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setTitle(InitialData('title'));
        setSubtitle(InitialData('subtitle'));
        setText(InitialData('text'));
        setType(InitialData('type'));
        setFile(InitialData('file'));
        setFileStatus(false);
        setBgFile(InitialData('bg_file'));
        setBgFileStatus(false);
        setImgSlideShow(InitialData('img_slide_show'));
        setImgSlideShowStatus(false);
        
        setBtn(InitialData('btn'));
        setSlideshow(InitialData('slideshow'));
    }, [dataPage, idPage]);

    return(
        <form className="page_content page_home" onSubmit={ SaveData }>
            <Comp_Topic type={ true } icon="save" SearchInput="" search="" title="Layout do site - Detalhes" OpenPage={ props.OpenPage } page="site" idPage={ 0 } />
            
            <div className="show_page_data">
                <div className="type_title">Informação da página</div>
                <div className="list_input_data">
                    <Comp_Input className="" type="text" index="not" setValue={ setTitle } value={ title } input="" maxLength={ 140 } placeholder="" required={ true } name="Título" />

                    {
                        origin === "Home" ?
                            <>
                                {
                                    type['value'] === 1 ?
                                        <Comp_Input className="" type="text" index="not" setValue={ setSubtitle } value={ subtitle } input="" maxLength={ 140 } placeholder="" required={ false } name="Subtítulo" />
                                    : null
                                } 

                                <Comp_Select index="not" name="Tipo de slideshow" className="type_page" opt={ [{ "value": 0, "label": "Lista de imagem" }, { "value": 1, "label": "Vídeo" }] } setValue={ setType } value={ type } input="" />
                            </>
                        : null
                    }
                    
                    <Comp_InputFile index="not" setValue={ setFile } value={ file } setStatus={ setFileStatus } status={ fileStatus } OpenFile={ props.OpenFile } thumbnail={ file } title1={ origin === "Home" ? "Adicionar vídeo" : "Adicionar imagem" } title2={ origin === "Home" ? "Vídeo adicionado" : "Imagem adicionado" } type={ origin === "Home" ? "video" : "img" } crop={ PopUpCrop } typeCrop="file" idCrop="0" />                  

                    {
                        origin === "Home" ? 
                        <Comp_InputFile index="not" setValue={ setImgSlideShow } value={ imgSlideShow } setStatus={ setImgSlideShowStatus } status={ imgSlideShowStatus } OpenFile={ props.OpenFile } thumbnail={ imgSlideShow } title1="Adicionar imagem do slide show" title2="Imagem do slide show adicionada" type="img" /> :
                        <>
                            {
                                origin === "Workshop" ? null :
                                <Comp_InputFile index="not" setValue={ setBgFile } value={ bgFile } setStatus={ setBgFileStatus } status={ bgFileStatus } OpenFile={ props.OpenFile } thumbnail={ bgFile } title1="Adicionar imagem de fundo" title2="Imagem de fundo adicionada" type="img" crop={ PopUpCrop } typeCrop="bg_img" idCrop="0" />
                            }

                            <Comp_Textarea index="not" name="Descrição" className="" setValue={ setText } value={ text } />
                        </>
                    }
                </div>
            </div>

            {
                origin === "Home" ?
                    type['value'] === 1 ? null :
                    <div className="show_page_data">
                        <div className="type_title">
                            <div className="">Lista de imagens</div>
                            <div className="" onClick={ ()=>{ setSlideshow([...slideshow, { "id": 0, "title": "", "file": "", "status": false }]) } }>Adicionar imagem</div>
                        </div>
                        <div className="list_input_data">
                            {
                                slideshow.map((elem, index)=>{
                                    return(
                                        <div className="div_show_btn" key={ index }>
                                            <Comp_Input className="" type="text" index={ index } setValue={ HandleDataSlideshow } value={ elem.title } input="title" maxLength={ 140 } placeholder="" required={ true } name="Título" />
                    
                                            <Comp_InputFile index={ index } setValue={ HandleDataSlideshow } value={ elem.file } setStatus={ HandleDataSlideshow } status={ elem.status } OpenFile={ props.OpenFile } thumbnail={ elem.file } title1="Adicionar imagem" title2="Imagem adicionado" type="img" />

                                            <div className="div_input" onClick={ ()=>{ DeleteDataSlideshow(elem.id, index, elem.title) } }>
                                                <Svg_Delete className="icons" color="#f00000" />
                                            </div>
                                        </div>
                                    )
                                })
                            }                        
                        </div>
                    </div>
                :
                origin === "Workshop" || origin === "Eventos" ?
                    btn.map((elem, index)=>{
                        return(
                            <div className="show_page_data" key={ index }>
                                <div className="type_title">
                                    <div className="">{ elem.title }</div>
                                </div>
                                <div className="list_input_data">
                                    <Comp_Input className="" type="text" index={ index } setValue={ HandleDataBtn } value={ elem.title } input="title" maxLength={ 140 } placeholder="" required={ false } name="Título" />
                    
                                    <Comp_InputFile index={ index } setValue={ HandleDataBtn } value={ elem.file } setStatus={ HandleDataBtn } status={ elem.status } OpenFile={ props.OpenFile } thumbnail={ elem.file } title1="Adicionar imagem" title2="Imagem adicionado" type="img" crop={ PopUpCrop } typeCrop="btn_file" idCrop={ elem.id } />

                                    <Comp_Textarea index={ index } name="Descrição" className="" setValue={ HandleDataBtn } value={ elem.text } input="text" />
                                </div>
                            </div>
                        )
                    })
                : null
            }
        </form>
    )
}